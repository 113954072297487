/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useEffect, useState } from "react";
import whiteNamewithLogo from "../../assets/logos/roambee-logo-whitebg.svg";
import beeIcon from "../../assets/logos/beeIcon.svg";
import { PageFirst, PageLast } from "@carbon/icons-react";
import { NavLink } from "react-router-dom";
import { LeftSideBarDiv } from "./styles";

// @ts-ignore
import { getRoutes, EventEmitter } from "@roambee/client-utility";
// @ts-ignore
import { Tooltip } from "@roambee/client-styleguide";

export default function LeftSideBar() {
	//navbar 80px-240px configuration
	const [isWide, setWide] = useState(true);
	const [routes, setRoutes] = useState([]);

	useEffect(() => {
		setTimeout(() => {
			const appRoutes = getRoutes();
			setRoutes(appRoutes);
		}, 1000);
	}, []);

	useEffect(() => {
		// Initial check
		handleResize();

		// Add event listener
		window.addEventListener("resize", handleResize);

		// Clean up event listener
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const handleToggle = (eventValue = null) => {
		const toggle = eventValue !== null ? eventValue : !isWide;
		setWide(toggle);
		EventEmitter.emit("toggleSideNav", toggle);
	};

	const handleResize = () => {
		if (window.innerWidth > 360 && window.innerWidth < 1200) {
			const container = document.querySelector(".grid-container");
			container?.classList.add("aside-small");
			handleToggle(false);
		} else {
			const container = document.querySelector(".grid-container");
			container?.classList.remove("aside-small");
			handleToggle(true);
		}
	};

	return (
		<LeftSideBarDiv>
			<aside role="menu" tabIndex={0} className={isWide ? "aside" : "aside aside-small"}>
				<ul className="aside_top">
					<li className="logoGroup">
						<img src={isWide ? whiteNamewithLogo : beeIcon} alt="logo" className="whiteNamewithLogo" />
						<span role="button" tabIndex={0} className="page-first-last" style={{ cursor: "pointer" }} onClick={() => handleToggle()}>
							{isWide ? <PageFirst size={20} /> : <PageLast size={20} />}
						</span>
					</li>
					<li className="menuGroup">
						{routes.map(
							(route, index) =>
								route.visible && (
									<NavLink to={route.path} key={index} className={({ isActive }) => (isActive ? "link-active" : "link")}>
										{isWide ? (
											<li className="aside-list-item centered-item">
												<div className="wideGroup">
													{" "}
													{route.icon}
													<span>{route.title}</span>{" "}
												</div>
											</li>
										) : (
											<li className="collapsedGroup" role="button" tabIndex={0}>
												{/* <div className="smallGroup">{route.icon}</div> */}
												<div className="smallGroup">
													<Tooltip text={route.title}>
														<div className="smallGroup">{route.icon}</div>
													</Tooltip>
												</div>
											</li>
										)}
									</NavLink>
								)
						)}
					</li>

					{/* accordion */}
					{/* <li className={isWide ? "aside-list-item disable" : "display-n"}>
						<div className="accordion">
							<div className="accordion-item">
								<div
									className="accordion-title"
									role="button"
									tabIndex={0}
									onClick={() => setShipmentOpen(!isShipmentOpen)}
								>
									<div
										className={
											isShipmentActive
												? "font-weight-bold text-yellowPrimary accordion-title-head"
												: "accordion-title-head"
										}
									>
										<DeliveryParcel size={20} />
										<div className="dropdownShipment">Shipments</div>
										<div className="text-white arrowIcon">
											{isShipmentOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
										</div>
									</div>
								</div>

								<ul
									className={isShipmentOpen ? "accordion-content" : "accordion-content display-n"}
									ref={accordionShipment}
								>
									{Shipment.map((item, index) => (
										<NavLink
											to={item.link}
											key={index}
											className={({ isActive }) => (isActive ? "link-active" : "link")}
										>
											<li className="dropdown-list">{item.title}</li>
										</NavLink>
									))}
								</ul>
							</div>
						</div>
					</li>

					<ul className={isWide ? "display-n" : "shipment_main-withIcon"}>
						<li
							className={
								isShipmentActive ? "collapsedGroup popup bg-yellowTransparent text-yellowPrimary" : "collapsedGroup"
							}
						>
							<div className="smallGroup">
								<Popup
									trigger={
										<div className={isShipmentActive ? "text-yellowPrimary" : ""}>
											<DeliveryParcel size={20} />{" "}
										</div>
									}
									position="right center"
									on="hover"
									closeOnDocumentClick
									mouseLeaveDelay={200}
									mouseEnterDelay={0}
									className="popup-main"
									arrow={false}
								>
									<div className="menu">
										{Shipment.map((item, index) => (
											<NavLink
												key={index}
												to={item.link}
												className={({ isActive }) => (isActive ? "link-active" : "link")}
											>
												<div className="menu-item" role="button" tabIndex={0} onClick={handleMenuItems}>
													{item.title}
												</div>
											</NavLink>
										))}
									</div>
								</Popup>
							</div>
						</li>{" "}
					</ul> */}
					{/* )} */}
				</ul>
				{/* oparations */}

				{/* <ul className="aside-oparations ">
					<li className={isWide ? "aside-list-item disable" : "display-n"}>
						<div className="accordion operations">
							<div className="accordion-item">
								<div
									className="accordion-title"
									role="button"
									tabIndex={0}
									onClick={() => setOperationOpen(!isOperationOpen)}
								>
									<div
										className={
											isOperationActive
												? "accordion-title-head text-yellowPrimary font-weight-bold"
												: "accordion-title-head"
										}
									>
										<ArrowsHorizontal size={20} />
										<div className={"dropdownOperation"}>Operations</div>
										<div className="text-white arrowIcon">
											{isOperationOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
										</div>
									</div>
								</div>

								<ul
									className={isOperationOpen ? "accordion-content" : "accordion-content display-n"}
									ref={accordionOperation}
								>
									{Operations.map((item, index) => {
										return (
											<NavLink
												to={item.link}
												key={index}
												className={({ isActive }) => (isActive ? "link-active" : "disable")}
											>
												<li className="dropdown-list operation">{item.title}</li>
											</NavLink>
										);
									})}
								</ul>
							</div>
						</div>
					</li>

					<ul className={isWide ? "display-n" : "shipment_main-withIcon"}>
						<li
							className={
								isOperationActive ? "collapsedGroup popup bg-yellowTransparent text-yellowPrimary " : "collapsedGroup"
							}
						>
							<div className="smallGroup">
								<Popup
									trigger={
										<div className={isOperationActive ? "text-yellowPrimary" : ""}>
											<ArrowsHorizontal size={20} />
										</div>
									}
									position="right center"
									on="hover"
									closeOnDocumentClick
									mouseLeaveDelay={200}
									mouseEnterDelay={0}
									className="popup-main"
									arrow={false}
								>
									<div className="menu">
										{Operations.map((item, index) => (
											<NavLink
												key={index}
												to={item.link}
												className={({ isActive }) => (isActive ? "link-active" : "link")}
											>
												<div className="menu-item" role="button" tabIndex={0} onClick={handleMenuItems}>
													{item.title}
												</div>
											</NavLink>
										))}
									</div>
								</Popup>
							</div>
						</li>{" "}
					</ul>
				</ul> */}

				{/* main */}
				{/* <ul className="aside_main">
					<div className={isWide ? "" : "display-n"}>
						<ul className="options" ref={accordionAsideMain}>
							{Main.map((item, index) => {
								return (
									<NavLink to={item.link} key={index} className={({ isActive }) => (isActive ? "link-active" : "link")}>
										<li className="aside-list-item">{item.title}</li>
									</NavLink>
								);
							})}
						</ul>
						<li className="aside-list-item disable aside_main-accordion">
							<div className="accordion">
								<div className="accordion-item">
									<div
										className="accordion-title"
										role="button"
										tabIndex={0}
										onClick={() => setAsideMainOpen(!isAsideMainOpen)}
									>
										<div
											className={
												isAsideMainActive
													? "accordion-title-head text-yellowPrimary font-weight-bold"
													: "accordion-title-head"
											}
										>
											<OverflowMenuVertical size={20} />
											<div className="dropdownMore">More</div>
											<div className="text-white arrowIcon">
												{isAsideMainOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
											</div>
										</div>
									</div>
									{isAsideMainOpen && (
										<ul className="accordion-content">
											{Main.map((item, index) => (
												<NavLink
													to={item.link}
													className={({ isActive }) => (isActive ? "link-active" : "link")}
													key={index}
												>
													<li className="dropdown-list more">{item.title}</li>
												</NavLink>
											))}
										</ul>
									)}
								</div>
							</div>
						</li>
					</div>
					<ul className={isWide ? "display-n" : "aside_main-withIcon "}>
						<li
							className={
								isAsideMainActive ? "collapsedGroup popup bg-yellowTransparent text-yellowPrimary" : "collapsedGroup"
							}
						>
							<div className="smallGroup">
								<Popup
									trigger={
										<div className={isAsideMainActive ? "text-yellowPrimary" : ""}>
											<OverflowMenuVertical size={20} />
										</div>
									}
									position="right center"
									on="hover"
									closeOnDocumentClick
									mouseLeaveDelay={200}
									mouseEnterDelay={0}
									className="popup-main"
									arrow={false}
								>
									<div className="menu">
										{Main.map((item, index) => (
											<NavLink
												key={index}
												to={item.link}
												className={({ isActive }) => (isActive ? "link-active" : "link")}
											>
												<div className="menu-item" role="button" tabIndex={0} onClick={handleMenuItems}>
													{item.title}
												</div>
											</NavLink>
										))}
									</div>
								</Popup>
							</div>
						</li>{" "}
					</ul>
				</ul> */}
				{/* main */}
				{/* footer */}
				{/* <ul className="aside_footer">
					<ul className={isWide ? "" : "display-n"} ref={footer}>
						{Footer.map((item, index) => (
							<NavLink key={index} to={item.link} className={({ isActive }) => (isActive ? "link-active" : "link")}>
								<li className="aside-list-item">{item.title}</li>
							</NavLink>
						))}
					</ul>
					<ul className={isWide ? "display-n" : "shipment_main-withIcon"}>
						<li
							className={
								isFooterActive ? "collapsedGroup popup bg-yellowTransparent text-yellowPrimary" : "collapsedGroup"
							}
						>
							<div className="smallGroup">
								<Popup
									trigger={
										<div className={isFooterActive ? "text-yellowPrimary" : ""}>
											<Help size={20} />{" "}
										</div>
									}
									position="top left"
									on="hover"
									closeOnDocumentClick
									mouseLeaveDelay={200}
									mouseEnterDelay={0}
									className="popup-footer"
									arrow={false}
								>
									<div className="menu">
										{Footer.map((item, index) => (
											<NavLink
												key={index}
												to={item.link}
												className={({ isActive }) => (isActive ? "link-active" : "link")}
											>
												<div className="menu-item" role="button" tabIndex={0} onClick={handleMenuItems}>
													{item.title}
												</div>
											</NavLink>
										))}
									</div>
								</Popup>
							</div>
						</li>{" "}
					</ul>
				</ul> */}
				{/* footer */}
			</aside>
		</LeftSideBarDiv>
	);
}
