import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AppThemes } from "@roambee/client-styleguide";

export const LeftSideBarDiv = styled.div`
	.aside {
		grid-area: aside;
		background-color: ${AppThemes.light.colors.white};
		color: ${AppThemes.light.colors.neutral900};
		position: relative;
		overflow: auto;
		width: 240px;
		height: 100%;
		cursor: pointer;
		z-index: 999;
		.display-n {
			display: none;
		}
		.link {
			color: ${AppThemes.light.colors.neutral900};
		}
		.menuGroup {
			margin-top: 36px;
		}
	}
	.aside.aside-small.aside_top {
		border-bottom: none;
	}

	.aside li span {
		padding-left: 12px;
	}

	.logoGroup {
		height: 59px;
	}

	.aside.aside-small img {
		width: 48%;
		height: auto;
		display: block;
		margin: 0px 14px;
	}
	.aside.aside-small .logoGroup {
		height: 27px;
	}

	.aside img {
		width: 40%;
		height: auto;
		display: block;
		margin: 0px 0px 24px 24px;
	}

	.page-first-last {
		display: none;
	}

	.aside.active {
		transform: translateX(0);
	}

	.aside_main,
	.aside_footer {
		padding: 0;
		list-style-type: none;
	}

	.aside_footer {
		margin-top: auto;
		margin-bottom: 24px;
	}

	.grid-container .aside-small .aside_footer {
		position: absolute;
		bottom: 28px;
		width: 100%;
		border: none;
	}

	.aside-small .aside_top {
		box-shadow: none;
	}

	.aside_footer li {
		display: flex;
		cursor: pointer;
	}
	.aside_main,
	.aside_footer li:hover {
		color: ${AppThemes.light.colors.yellowBee200};
	}
	// .aside_main-withIcon {
	// 	margin-left: 12px;
	// }

	.aside_top {
		margin-top: 24px;
		padding: 0;
		list-style-type: none;
		cursor: pointer;
	}

	.aside-list-item {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 4px 14px;
		margin: 12px 24px;
		color: ${AppThemes.light.colors.neutral900};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: normal;
	}

	.isWide {
		.aside-list-item {
			padding: 4px 18px;
			margin: 12px 24px;
		}
	}

	.aside-list-item:not(.disable):hover {
		background-color: ${AppThemes.light.colors.white};
		color: ${AppThemes.light.colors.yellowBee200};
		font-weight: ${AppThemes.light.weights.bold700};
		transition: background-color 0.2s ease-in-out;
		cursor: pointer;
		border-radius: 4px;
	}

	//active links

	.aside-small .link-active li {
	}

	.link-active li {
		color: ${AppThemes.light.colors.yellowBee200} !important;
		font-weight: ${AppThemes.light.weights.bold700} !important;
		border-radius: 4px;
	}

	.accordion-title-head {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 100px;
	}

	.accordion-content {
		width: fit-content;
	}

	.menu-icon {
		position: fixed;
		display: flex;
		top: 4px;
		left: 8px;
		align-items: center;
		justify-content: center;
		z-index: 1;
		cursor: pointer;
		padding: 12px;
		color: ${AppThemes.light.colors.neutral900};
	}

	.aside_close-icon {
		position: absolute;
		visibility: visible;
		left: 20px;
		top: 16px;
		cursor: pointer;
		color: ${AppThemes.light.colors.neutral900};
	}

	.dropdown-list {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 5px 15px;
		margin: 14px 14px;
		color: ${AppThemes.light.colors.neutral900};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: normal;
	}

	.dropdown-list.operation {
		padding: 5px 28px 5px 15px;
	}

	.dropdown-list.more {
		padding: 5px 3px 5px 15px;
	}

	.dropdown-list:hover {
		background-color: ${AppThemes.light.transparent.transparent1200};
		transition: background-color 0.2s ease-in-out;
		cursor: pointer;
		border-radius: 4px;
	}

	.arrow {
		color: ${AppThemes.light.colors.neutral500};
	}

	.menu {
		width: 200px;
		display: flex;
		flex-direction: column;
		background: ${AppThemes.light.colors.purpleSunset800};
		border-radius: 4px;
		box-shadow: $elevation12;
		height: 100%;
		cursor: pointer;
	}
	.menu-item {
		cursor: pointer;
		padding: 12px 6px 12px 12px;
		color: ${AppThemes.light.colors.neutral900};
	}
	.menu-item-icon {
		padding: 4px 16px 2px 12px;
		margin: 8px;
		border-radius: 4px;
		display: flex;
		justify-content: center;
	}

	.menu-item-icon:hover {
		background: ${AppThemes.light.transparent.transparent1200};
		transition: background-color 0.2s ease-in-out;
	}

	.menu-item:hover {
		background-color: ${AppThemes.light.transparent.neutral100};
		transition: background-color 0.2s ease-in-out;
	}

	.accordion-title {
		display: flex;
		justify-content: space-between;
		cursor: pointer;
		position: relative;
	}

	.accordion-title:hover:after {
		border-radius: 4px;
		padding: 5px 8px 4px 2px;
		margin: 0px 4px -2px -15px;
		position: absolute;
		width: 182px;
		background: ${AppThemes.light.transparent.transparent1200};
		content: "";
		top: -4px;
		left: 1px;
		right: 1px;
		bottom: -2px;
	}

	.accordion-title-head {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.accordion-title,
	.accordion-content {
		list-style-type: none;
	}

	.dropdownShipment {
		margin: 0px 33px 0px 10px;
	}
	.dropdownOperation {
		margin: 0px 30px 0px 10px;
	}
	.dropdownMore {
		margin: 0px 77px 0px 8px;
	}
	.aside-list-item.active {
		display: none;
	}

	.shipment_main-withIcon,
	.aside_main-withIcon {
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin: -7px;
	}
	.aside_main-withIcon {
		margin-top: 2px;
	}

	//popup menu
	.link-active > div {
		background-color: ${AppThemes.light.transparent.neutral200} !important;
		font-weight: $base-font-weight-bold;
		border-radius: 4px;
	}
	.link-active > div:hover {
		background-color: ${AppThemes.light.transparent.neutral100};
		border-radius: 4px;
	}

	.popup-main-content {
		margin-left: 28px;
	}
	.popup-operation-content {
		margin-left: 8px;
	}
	.popup-footer-content {
		margin-left: 40px;
	}
	.arrowIcon {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	//toppled navbar icons

	.dashboard-item {
		margin-top: 38px;
		align-items: center;
		display: flex;
	}

	.centered-item {
		align-items: center;
		display: flex;
	}
	.centered-item .wideGroup {
		display: flex;
		align-items: center;
	}

	.dashboard-item .wideGroup {
		display: flex;
		align-items: center;
	}

	.collapsedGroup {
		padding: 2px 12px 0px 12px;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		color: ${AppThemes.light.transparent.neutral100};
		align-items: center;
		margin: 8px 16px;
	}

	.collapsedGroup.dashboard {
		margin-top: 24px;
	}

	.collapsedGroup:hover {
		background-color: ${AppThemes.light.transparent.transparent1200};
		transition: background-color 0.2s ease-in-out;
		cursor: pointer;
		border-radius: 4px;
	}
	.collapsedGroup.popup:hover {
		background-color: ${AppThemes.light.transparent.yellow};
	}

	.smallGroup {
		margin-top: 1px;
		padding: 2px;
	}

	@media only screen and (max-width: 360px) {
		.aside img {
			display: none;
		}
		.aside_footer {
			position: relative;
			left: 5px;
			top: 5px;
		}
		.logo1 {
			display: none;
		}
		.aside-list-item.active {
			display: flex;
		}
		.aside_main .options {
			display: none;
		}
		.logoGroup {
			height: 10px;
		}
	}

	/* responsive layout */
	@media only screen and (min-width: 360px) {
		// .grid-container {
		// 	display: grid;
		// 	grid-template-columns: 240px 1fr;
		// 	grid-template-rows: 1fr;
		// 	grid-template-areas: "aside main";
		// 	height: 100vh;
		// 	background-color: ${AppThemes.light.transparent.neutral200};
		// }

		.grid-container.aside-small {
			grid-template-columns: 80px 1fr;
			position: relative;
			.aside-list-item {
				padding: 6px 18px;
			}
		}

		// .aside_close-icon {
		// 	display: none;
		// }

		// .header {
		// 	display: none;
		// }

		.aside {
			display: flex;
			flex-direction: column;
			position: relative;
			transform: translateX(0);
		}

		.aside.aside-small {
			width: 80px;
			overflow: initial;
			.aside_footer {
				.menu-item-icon {
					padding: 4px 16px 2px 12px;
					margin: 8px 16px;
				}
			}
		}
		.aside.aside-small .aside_top {
			border: none;
		}

		.page-first-last {
			background-color: ${AppThemes.light.colors.white};
			display: block;
			float: right;
			margin-right: 32px;
			cursor: pointer;
			position: relative;
			top: -49px;
		}

		.aside.aside-small .page-first-last {
			position: absolute;
			background-color: ${AppThemes.light.colors.white};
			top: 28px;
			left: 65px;
			background-color: $P800;
			z-index: 5;
			border-radius: 3px;
			padding: 5px;
			display: flex;
			align-items: center;
		}
	}

	// /*media for tablet portrait*/
	// @media screen and (max-width: 900px) and (min-width: 768px) {
	// 	.aside img {
	// 		display: none;
	// 	}
	// 	.aside-list-item.active {
	// 		display: flex;
	// 	}
	// 	.aside_main .options {
	// 		display: none;
	// 	}
	// 	.logoGroup {
	// 		height: 10px;
	// 	}
	// }

	/*tablet landscape*/

	@media screen and (max-width: 1200px) and (min-width: 360px) {
		.aside {
			position: fixed;
			top: 0px;
		}
		.aside-list-item.active {
			display: block;
		}
		.aside_main .options {
			display: none;
		}
	}

	/*desktop small*/

	@media screen and (max-width: 1440px) and (min-width: 1200px) {
		.aside {
			position: fixed;
			top: 0px;
		}

		.aside_main .options {
			display: block;
		}

		.aside_top {
			// box-shadow: 0px 24px 3px -24px ${AppThemes.light.transparent.transparent1200};
		}

		.aside-list-item.aside_main-accordion {
			display: none;
		}
	}

	/*desktop big size*/

	@media screen and (min-width: 1440px) {
		.aside {
			position: fixed;
			top: 0px;
		}

		.aside_main .options {
			display: block;
		}
		.aside_top {
			// box-shadow: 0px 24px 3px -24px ${AppThemes.light.transparent.transparent1200};
		}
		.aside-list-item.aside_main-accordion {
			display: none;
		}
	}
`;
