import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AppThemes, GlobalStyle } from "@roambee/client-styleguide";

import LeftSideBar from "./components/LeftSideBar";

export default function Root() {
	return (
		<BrowserRouter>
			<section>
				<ThemeProvider theme={AppThemes?.light}>
					<GlobalStyle />
					<LeftSideBar />
				</ThemeProvider>
			</section>
		</BrowserRouter>
	);
}
